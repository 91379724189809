@import 'assets/styles/mixins.scss';

.container {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.amount {
  color: $black;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0 !important;

  @media (min-width: $md-min-width) and (max-width: $xxl-max-width) {
    font-size: 16px;
  }

}

.numOfTransactions {
  color: #757575;
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 0 !important;
  margin-top: 0;

  @media screen and (min-width: $md-min-width) and (max-width: $xl-max-width) {
    font-size: 11px;
  }
}

.statistic {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 56px;
  width: 56px;
}

.percent {
  font-size: 13px;
  font-weight: 300;
  margin-bottom: 0 !important;

  @media screen and (min-width: $md-min-width) and (max-width: $xl-max-width) {
    font-size: 11px;
  }
}

.descriptionBottomRight {
  color: #757575;
  font-size: 13px;
  margin-bottom: 0 !important;
  margin-top: 15px;
  text-align: right;

  @media screen and (min-width: $md-min-width) and (max-width: $xl-max-width) {
    font-size: 11px;
  }
}
