@import 'assets/styles/mixins.scss';

.topbar {
  background: $white;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.icon-main-btn {
  width: 20px;
  line-height: 1 !important;
  color: $text;
  margin-right: rem(8);
}

.logoContainer {
  height: 64px;
  padding: 20px 20px 0px 22px;
  img {
    height: 36px;
  }
}
